/**
 * Created by neo on 18.11.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ExploreEntryCoachUrl } from './ExploreEntryCoachUrl';
import { Col, Row } from 'reactstrap';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import AsyncSelect from 'react-select/async';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import dayjs, { Dayjs } from 'dayjs';
import CreatableSelect from 'react-select/creatable';
import { ExploreCategory } from '../../../../Model/Explore/ExploreCategory';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { reaction, runInAction } from 'mobx';
import { notUndefined } from '../../../../Utils/notUndefined';
import { useParams } from 'react-router-dom';
import { TagConditionsInput } from '../../SuperMacro/View/TagConditionsInput';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { ExploreEntryModalVersionEditor } from './ExploreEntryModalVersionEditor';
import { Gym } from '../../../../Model/Gym/Gym';
import { availableLanguages } from '../../../../Utils/availableLanguages';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { LocalizedValue } from '../../../../Model/LocalizedValue';
import { WorkoutTemplateEntry } from '../../../../Model/Explore/WorkoutTemplateEntry';
import { OnlineMeetingEntry } from '../../../../Model/Explore/OnlineMeetingEntry';
import { OfflineMeetingEntry } from '../../../../Model/Explore/OfflineMeetingEntry';
import { ActivityWorkoutEntry } from '../../../../Model/Explore/ActivityWorkoutEntry';

const allTypes = [
  'article',
  'activityWorkout',
  'workplace',
  'workoutTemplate',
  'meditation',
  'breathing',
  'yoga',
  'onlineMeeting',
  'offlineMeeting',
  'video',
  'webinar',
  'link',
];

const typeNameMap = {
  article: 'Article',
  activityWorkout: 'Activity Workout',
  workplace: 'Workplace Workout',
  workoutTemplate: 'Workout Template',
  meditation: 'Meditation',
  breathing: 'Breathing',
  yoga: 'Yoga',
  onlineMeeting: 'Online Meeting',
  offlineMeeting: 'Offline Meeting',
  video: 'Video',
  webinar: 'Webinar',
  link: 'Link',
};

const compatibleTypeMap = {
  default: allTypes,
  article: ['article', 'webinar'],
  activityWorkout: ['activityWorkout', 'video', 'yoga'],
  workplace: ['workplace', 'workoutTemplate'],
  workoutTemplate: ['workoutTemplate', 'workplace'],
  meditation: ['meditation'],
  breathing: ['breathing'],
  yoga: ['yoga', 'activityWorkout', 'video'],
  onlineMeeting: ['onlineMeeting', 'offlineMeeting', 'webinar'],
  offlineMeeting: ['offlineMeeting', 'onlineMeeting', 'webinar'],
  video: ['video', 'activityWorkout', 'yoga', 'webinar'],
  webinar: ['webinar', 'onlineMeeting', 'offlineMeeting'],
  link: ['link'],
};

const allowedTags = [
  'duration:short',
  'duration:medium',
  'duration:long',
  'age:junior',
  'age:adult',
  'age:senior',
  'age:elder',
  'gender:male',
  'gender:female',
  'activity:sitting',
  'activity:standing',
  'activity:physical',
  'equipment:yes',
  'equipment:no',
  'equipment:bottle',
  'level:beginner',
  'level:intermediate',
  'level:advanced',
].sort((a, b) => a.localeCompare(b));

const defaultOptions = allowedTags.map((value) => ({ value, label: value }));

export type TabBasicInfoProps = {
  entry: ExploreEntry;
  onEntryChanged: (newEntry: ExploreEntry) => void;
};

export const TabBasicInfo: React.FC<TabBasicInfoProps> = observer(({ entry, onEntryChanged }) => {
  const { contentId } = useParams<{ contentId: string }>();

  const [gym, setGym] = useState<Gym | undefined>();
  const [selectedCategories, setSelectedCategories] = useState<ExploreCategory[]>([]);
  const [generatingTags, setGeneratingTags] = useState(false);
  const [allowTypeChange, setAllowTypeChange] = useState(false);

  const includedTags = entry.tags.map((value) => ({ label: value, value }));
  const filterTags = entry.filterTags.map((value) => ({ label: value, value }));
  const includedTagCategories = entry.tags.map((t) => t.split(':')[0]).filter(notUndefined);

  const allowedIncludedTags = defaultOptions.filter(
    ({ value }) => !includedTagCategories.some((cat) => value.startsWith(cat)),
  );

  const isNew = contentId === 'new';
  const isDefault = entry.type === 'default';

  const possibleLanguages = availableLanguages.filter((a) => !entry.availableLanguages.some((e) => e === a.value));

  const allowedTypes = compatibleTypeMap[entry.type] ?? compatibleTypeMap.default;

  useEffect(
    () =>
      reaction(
        () => entry.gymId,
        (gymId) => {
          setGym(undefined);
          if (gymId) {
            Gym.get(gymId).then((res) => setGym(res));
          }
        },
        { fireImmediately: true },
      ),
    [entry],
  );

  useEffect(
    () =>
      reaction(
        () => entry.categories.map((c) => c),
        (categories) => {
          console.log('cats', categories);
          if (categories.length > 0) {
            ExploreCategory.findAll(categories, { gymId: entry.gymId }).then((result) => {
              console.log('new cats', result);
              setSelectedCategories(result);
            });
          } else {
            setSelectedCategories([]);
          }
        },
        { fireImmediately: true },
      ),
    [entry],
  );

  const handleChange = React.useCallback(
    (parents: ExploreCategory[] | undefined) => {
      console.log('changed categories', parents);
      runInAction(() => (entry.categories = (parents ?? []).map((c) => c.identifier)));
    },
    [entry],
  );

  const fetchCategories = React.useCallback(
    (query: string) => ExploreCategory.find({ query, gymId: entry.gymId }),
    [entry],
  );

  const getOptionLabel = React.useCallback(
    (option: ExploreCategory) => `${option.defaultName} (${option.identifier})`,
    [],
  );

  const getOptionValue = React.useCallback((option: ExploreCategory) => option, []);

  const handleChangeType = React.useCallback(
    (type) => {
      if (entry.type !== type) {
        onEntryChanged(ExploreEntryBuilder.buildFromType(type, entry.toJS()));
      }
    },
    [entry, onEntryChanged],
  );

  const handleChangePublishDate = React.useCallback(
    (date) => runInAction(() => (entry.publishDate = date?.toDate())),
    [entry],
  );

  const handleChangeUnpublishDate = React.useCallback(
    (date) => runInAction(() => (entry.unpublishDate = date?.toDate())),
    [entry],
  );

  const handleTagsChange = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        entry.tags.splice(0, entry.tags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => entry.tags.push(t));
      });
    },
    [entry],
  );

  const handleChangeFilterTags = React.useCallback(
    (tags?: any[]) => {
      runInAction(() => {
        entry.filterTags.splice(0, entry.filterTags.length);
        (tags ?? []).map((t) => t.value).forEach((t) => entry.filterTags.push(t));
      });
    },
    [entry],
  );

  const handleChangeOrder = React.useCallback(
    (count?: number | string | null) => {
      const limit = typeof count === 'string' ? Number(count) : count ?? 0;
      runInAction(() => (entry.order = limit));
    },
    [entry],
  );

  const fetchGym = React.useCallback((query: string) => Gym.find({ query, size: 100 }), []);

  const optionLabel = React.useCallback((obj) => obj.name, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeObject = React.useCallback(
    (value?: Gym) => {
      runInAction(() => (entry.gymId = value?.id));
    },
    [entry],
  );

  const handleChangeExportDisabled = React.useCallback(
    ({ target: { checked } }: any) => {
      entry.exportDisabled = checked;
    },
    [entry],
  );

  const fetchDescription = (entry: ExploreEntry) => {
    if (entry.getDescription('en')?.trim()) {
      return Promise.resolve(entry.getDescription('en')?.trim());
    } else if (entry instanceof WorkoutTemplateEntry) {
      return entry.fetchWorkoutTemplate().then((t) => t?.getDescription('en')?.trim() ?? '');
    }
    return Promise.resolve('');
  };

  const fetchPrompt = (entry: ExploreEntry) => {
    return fetchDescription(entry).then((description) => {
      if (entry instanceof WorkoutTemplateEntry) {
        if (entry.context.equipmentTypes.length > 0) {
          if (entry.context.isWithoutEquipment) {
            return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
              'en',
            )}. It does not require any equipment and it's done only using your bodyweight. Include the tags "without equipment" and "bodyweight". ${
              entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
            } It has the following description: "${description}"`;
          }
          return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName('en')}.${
            entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
          } It needs following equipment: ${entry.context.equipmentTypes
            .filter((e) => e !== 'FREE' && e !== 'FREE_OUTDOOR')
            .join(',')}. Include minimum the tag "with equipment". It has the following description: "${description}"`;
        }

        return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName('en')}.${
          entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
        } It can be done in a gym or with your own equipment at home. Include minimum the tag "with equipment". It has the following description: "${description}"`;
      }
      return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
        'en',
      )}. It has the following description: "${description}"`;
    });
  };

  const handleGenerateTags = React.useCallback(() => {
    setGeneratingTags(true);
    fetchPrompt(entry)
      .then((prompt) =>
        new GptResponseService([
          {
            role: 'system',
            content: 'Provide all responses as JSON array containing only strings.',
          },
          {
            role: 'user',
            content: prompt,
          },
        ]).generate(),
      )
      .then((result) => JSON.parse(result))
      .then((res) =>
        runInAction(
          () =>
            (entry.tags = [
              ...new Set<string>(
                res.map((t) => t.toLowerCase().trim()).concat(['afternoon', 'night', 'evening', 'morning']),
              ),
            ]),
        ),
      )
      .finally(() => setGeneratingTags(false));
  }, [entry, fetchPrompt]);

  const fetchType = (type) => {
    if (type === 'workoutTemplate') {
      return ' single workout';
    } else if (type === 'workplace') {
      return 'a single workout that you can do at work';
    } else if (type === 'activityWorkout') {
      return 'a single video based workout';
    } else if (type === 'meditation') {
      return 'a meditation';
    } else if (type === 'breathing') {
      return 'a breathing exercise';
    } else if (type === 'yoga') {
      return 'a video based single yoga class';
    } else if (type === 'onlineMeeting') {
      return 'an online live video';
    } else if (type === 'offlineMeeting') {
      return 'an on site event';
    }
    return 'an article';
  };

  const additionalGptInfo = (entry: ExploreEntry): string => {
    if (entry instanceof OnlineMeetingEntry || entry instanceof OfflineMeetingEntry) {
      return `The hosts of this event are ${entry.hosts.map((h) => `${h.name} (${h.description})`).join(', ')}.`;
    }
    if (entry instanceof ActivityWorkoutEntry) {
      if (entry.instructor?.name) {
        return `The meditation is guided by ${entry.instructor?.name}.`;
      }
    }

    return '';
  };

  const handleGenerateDescription = React.useCallback(() => {
    setGeneratingTags(true);
    const type = fetchType(entry.type);
    const additionalInfo = additionalGptInfo(entry);

    fetchDescription(entry)
      .then((description) =>
        new GptResponseService([
          {
            role: 'user',
            content: `Write a short and appealing description about ${type} named ${entry.getName(
              'en',
            )}. Omit dynamic information. Use the following tags: "${
              entry.tags
            }" but don't list them again in the output. ${
              description ? `and the following description: "${description}".` : ''
            } ${additionalInfo}. Use markdown to format the text.`,
          },
        ]).generate((result) =>
          runInAction(() => (entry.description = [new LocalizedValue({ lang: 'en', value: result })])),
        ),
      )
      .then((res) => runInAction(() => (entry.description = [new LocalizedValue({ lang: 'en', value: res })])))
      .finally(() => setGeneratingTags(false));
  }, [entry]);

  const handleGenerateShortDescription = React.useCallback(() => {
    setGeneratingTags(true);
    fetchDescription(entry)
      .then((description) =>
        new GptResponseService([
          {
            role: 'user',
            content: `Shorten the following description using maximal 96 characters: "${description}`,
          },
        ]).generate((result) =>
          runInAction(() => (entry.shortDescription = [new LocalizedValue({ lang: 'en', value: result })])),
        ),
      )
      .then((res) => runInAction(() => (entry.shortDescription = [new LocalizedValue({ lang: 'en', value: res })])))
      .finally(() => setGeneratingTags(false));
  }, [entry]);

  return (
    <React.Fragment>
      <ExploreEntryCoachUrl entry={entry} />
      <Row>
        <Col>
          <Form.Item label="Categories">
            <AsyncSelect
              defaultOptions
              isDisabled={isDefault}
              isMulti={true}
              isClearable={true}
              loadOptions={fetchCategories}
              value={selectedCategories}
              hideSelectedOptions={false}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue as any}
              onChange={handleChange as any}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Type">
            <Select value={entry.type} disabled={!isNew && !allowTypeChange} onChange={handleChangeType}>
              {allowedTypes.map((type) => (
                <Select key={type} value={type}>
                  {typeNameMap[type]}
                </Select>
              ))}
            </Select>
            {!allowTypeChange && (
              <Button type="link" onClick={() => setAllowTypeChange(true)}>
                Change Type
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Available Languages" extra="Automatically filled out by the languages of the videos">
            <CreatableSelect
              isDisabled={true}
              isClearable
              isMulti
              options={possibleLanguages}
              value={entry.availableLanguages.map(
                (value) => availableLanguages.find((a) => a.value === value) ?? { label: availableLanguages, value },
              )}
              placeholder="Available languages"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Gym / Company">
            <AsyncSelect
              cacheOptions
              isDisabled={isDefault}
              value={gym}
              isClearable={true}
              loadOptions={fetchGym as any}
              getOptionLabel={optionLabel}
              getOptionValue={optionValue as any}
              onChange={handleChangeObject as any}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Export Disabled" help="Content wont't be exported to third parties like ÖKK">
            <Checkbox onChange={handleChangeExportDisabled} checked={entry.exportDisabled} disabled={isDefault}>
              Export Disabled
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <TranslationInputArray entity={entry} field="name" label="Name" disabled={isDefault} />
        </Col>
        <Col xs={4}>
          <TranslationInputArray
            disabled={generatingTags || isDefault}
            entity={entry}
            type="textarea"
            field="description"
            label="Description"
            markdown={true}
          />
          {entry.description.length === 0 && (
            <Button type="link" onClick={handleGenerateDescription} disabled={generatingTags || isDefault}>
              Generate Description
            </Button>
          )}
        </Col>
        <Col xs={4}>
          <TranslationInputArray entity={entry} type="textarea" field="quotes" label="Quotes" disabled={isDefault} />
        </Col>
        <Col xs={4}>
          <TranslationInputArray
            entity={entry}
            disabled={isDefault}
            type="textarea"
            field="shortDescription"
            label="Short Description"
            maxLength={128}
          />
          {entry.description.length > 0 && entry.shortDescription.length === 0 ? (
            <Button type="link" onClick={handleGenerateShortDescription} disabled={generatingTags}>
              Generate Short Description
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Form.Item label="Valid From">
            <DatePicker
              disabled={isDefault}
              value={entry.publishDate ? dayjs(entry.publishDate) : undefined}
              onChange={handleChangePublishDate}
              allowClear={true}
            />
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item label="Valid Until">
            <DatePicker
              disabled={isDefault}
              value={entry.unpublishDate ? dayjs(entry.unpublishDate) : undefined}
              onChange={handleChangeUnpublishDate}
              allowClear={true}
            />
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item label="Order" extra="The lower the number the higher it is in the list. (0 = on top)">
            <InputNumber value={entry.order} onChange={handleChangeOrder} min={0} disabled={isDefault} />
          </Form.Item>
        </Col>
        <Col md={3}>
          <Form.Item label="Entry Tags">
            <CreatableSelect
              isClearable
              isMulti
              options={allowedIncludedTags}
              onChange={handleTagsChange as any}
              value={includedTags}
              placeholder="Tags"
              isDisabled={generatingTags || isDefault}
            />
          </Form.Item>
          <Button type="link" onClick={handleGenerateTags} disabled={generatingTags || isDefault}>
            Generate Tags
          </Button>
        </Col>
        <Col md={3}>
          <Form.Item label="Filter Tags">
            <CreatableSelect
              isClearable
              isMulti
              value={filterTags}
              placeholder="Filter Tags"
              onChange={handleChangeFilterTags as any}
            />
          </Form.Item>
        </Col>
      </Row>
      <SingleColRow>
        <Form.Item label="Tag Conditions">
          <TagConditionsInput tagConditions={entry.tagConditions} disabled={isDefault} />
        </Form.Item>
      </SingleColRow>
      <ExploreEntryModalVersionEditor entry={entry} />
    </React.Fragment>
  );
});
