/**
 * Created by katarinababic on 12.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner, Table } from 'reactstrap';
import { DatePicker, Form, Space } from 'antd';
import dayjs from 'dayjs';
import { AnalyticsData } from '../../../Model/Analytics/AnalyticsData';
import { totalEventsPerGym } from './studioAnalyticsQueries';
import { Company } from './StudioAnalyticsScreen';

const rowStyle = {
  paddingTop: 16,
};

const spinnerColStyle = {
  paddingTop: 80,
  paddingBottom: 80,
};

type TableRow = {
  companyId: string;
  companyName: string;
  totalEvents: number;
};

export type StudioTotalEventsPerCompanyProps = {
  companies: Company[];
};

export const StudioTotalEventsPerCompany: React.FC<StudioTotalEventsPerCompanyProps> = observer(({ companies }) => {
  const [startDate, setStartDate] = useState(dayjs('2024-08-01', 'YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs());
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setTableData([]);
    setLoading(true);
    AnalyticsData.query({
      query: totalEventsPerGym,
      parameters: {
        start_date: {
          value: startDate.format('YYYYMMDD'),
        },
        end_date: {
          value: endDate.format('YYYYMMDD'),
        },
      },
    })
      .then((res) => {
        const displayData = res.map((row) => {
          return {
            companyId: row['gymId'],
            companyName: companies.find((company) => company.id === row['gymId'])?.name ?? 'Unknown',
            totalEvents: parseInt(row['total_events']),
          };
        });
        setTableData(displayData);
      })
      .finally(() => setLoading(false));
  }, [startDate, endDate]);

  const handleChangeStartDate = React.useCallback((date) => setStartDate(date ?? dayjs().subtract(1, 'month')), []);

  const handleChangeEndDate = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

  return loading ? (
    <Row>
      <Col style={spinnerColStyle}>
        <Spinner />
      </Col>
    </Row>
  ) : (
    <React.Fragment>
      <Row style={rowStyle}>
        <Col>
          <h5>{'Total events per company'}</h5>
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col>
          <Space>
            <Form.Item label="Start Date">
              <DatePicker value={startDate} onChange={handleChangeStartDate} minDate={dayjs('2024-08-01')} />
            </Form.Item>
            <Form.Item label="End Date">
              <DatePicker value={endDate} onChange={handleChangeEndDate} />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Table>
          <thead>
            <tr>
              <th>{'Company Id'}</th>
              <th>{'Company Name'}</th>
              <th>{'Count of total events'}</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr key={row.companyId}>
                <td>{row.companyId}</td>
                <td>{row.companyName}</td>
                <td>{row.totalEvents}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </React.Fragment>
  );
});
