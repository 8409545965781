import { GptResponseService } from '../../../../Services/GptResponseService';
import { runInAction } from 'mobx';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { WorkoutTemplateEntry } from '../../../../Model/Explore/WorkoutTemplateEntry';
import { ActivityWorkoutEntry } from '../../../../Model/Explore/ActivityWorkoutEntry';
import { TranslationKey } from '../../../../Model/Translation/TranslationKey';
import { availableLanguages } from '../../../../Utils/availableLanguages';

// Fixed set of tags
const fixedTags = [
  'stress_management',
  'wellbeing',
  'movement',
  'flexibility',
  'strength',
  'mobility',
  'meditation',
  'breathing_exercises',
  'yoga',
  'pilates', // Added
  'aerobic',
  'cardio', // Added
  'high_intensity',
  'low_intensity',
  'relaxation',
  'focus',
  'recovery',
  'posture_correction',
  'pain_management',
  'balance',
  'core_strength',
  'sleep_health',
  'self_care',
  'emotional_balance',
  'work_life_balance',
  'workplace_wellbeing',
  'personal_growth',
  'strength_training', // Added
  'nutrition', // Added
  'hiit', // Added
];

// Updated fetchPrompt function to use fixed set of tags
const fetchPrompt = (entry: ExploreEntry): Promise<string> => {
  // Include only relevant properties to avoid exceeding token limits
  const relevantData: any = {};

  // Include equipment information if available
  if (entry instanceof WorkoutTemplateEntry) {
    relevantData.equipmentTypes = entry.context?.equipmentTypes;
    relevantData.isWithoutEquipment = entry.context?.isWithoutEquipment;
  }

  // Combine relevant data with the entry data
  const entryJson = JSON.stringify(Object.assign(relevantData, entry.toJS()), null, 2);

  const basePrompt = `Based on the following ExploreEntry JSON, provide exactly 3 tags (or fewer if less are applicable) that best describe the entry. Under no circumstances should you provide more than 3 tags.`;
  const contextExplanation = `These tags will be used later for aggregation and filtering purposes. They should capture the essence of the entry in simple terms.`;

  // Adjusted tag instructions
  const tagInstructions = `Select up to three tags from the following fixed set of tags that best fit the entry:

      ${fixedTags.map(tag => `"${tag}"`).join(', ')}

      Only use tags from the provided list. Do not include any tags not in the list.`;

  // Equipment-related instructions
  let equipmentInstructions = '';
  if (entry instanceof WorkoutTemplateEntry || entry instanceof ActivityWorkoutEntry) {
    equipmentInstructions = `\n\nBased on the equipment information:
- If the workout requires no equipment (e.g., "isWithoutEquipment" is true), always include the tag "equipment_free".
- If the workout requires custom equipment, include the tag "equipment_custom".
- If the workout requires gym equipment, include the tag "equipment_gym".
- For video-based workouts (type "activityWorkout"), they usually require no equipment; include "equipment_free" unless specified otherwise.
Remember, the total number of tags including equipment tags must not exceed 3.`;
  }

  return Promise.resolve(
    `${basePrompt}\n\n${contextExplanation}\n\n${tagInstructions}${equipmentInstructions}\n\nExploreEntry JSON:\n${entryJson}`
  );
};

// Optimized generateExploreFilterTags function
export const generateExploreFilterTags = (entry: ExploreEntry) =>
  fetchPrompt(entry)
    .then((prompt) =>
      new GptResponseService([
        {
          role: 'system',
          content:
            'Provide all responses as a JSON array containing only strings. Each tag should be a single word, using underscores to separate words if necessary, and should not contain spaces or special characters (only underscores are allowed). The response must start with "[" and end with "]". It MUST be a JSON array only—no other characters or text before or after the JSON array.',
        },
        {
          role: 'user',
          content: prompt,
        },
      ]).generate(),
    )
    .then((result) => JSON.parse(result))
    .then((res) => {
      const tags = [
        ...new Set<string>(
          res.map(
            (t) =>
              t
                .toLowerCase()
                .trim()
                .replace(/\s+/g, '_') // Replace spaces with underscores
                .replace(/[^\w_]/g, ''), // Remove special characters except underscores
          ),
        ),
      ];
      runInAction(() => {
        // Ensure the tags are lowercased, trimmed, replace spaces with underscores, and are unique
        entry.filterTags = tags;
        console.log('Generated filter tags:', entry.filterTags);
      });
      return tags;
    })
    .then((tags) =>
      Promise.all(
        tags
          .map((tag) => `explore.filter_tag.${tag}`)
          .map((tag) => {
            const languageOrder = ['en', 'de', 'fr', 'it'];
            return TranslationKey.findOne(tag, 'coach-app').then((res) => {
              if (!res) {
                // Prepare the prompt for translation
                const translationPrompt = `Translate the following tag into English, German, French, and Italian, in exactly that order. The translation should be as short as possible, as fitting as possible, in an app context. An emoji at the beginning is very much welcome in that translation.

Tag: "${tag.replace('explore.filter_tag.', '')}"

Provide the translations as a JSON array containing only the translated strings in the specified order. No other text should be included in the response.`;

                // Send the prompt to ChatGPT
                return new GptResponseService([
                  {
                    role: 'system',
                    content:
                      'Provide all responses as a JSON array containing only strings. The response must start with "[" and end with "]". It MUST be a JSON array only—no other characters or text before or after the JSON array.',
                  },
                  {
                    role: 'user',
                    content: translationPrompt,
                  },
                ])
                  .generate()
                  .then((result) => {
                    // Parse the JSON array of translations
                    const translations = JSON.parse(result);

                    // Map translations to the correct languages
                    return Promise.all(
                      languageOrder.map((languageCode, index) => {
                        const translation = translations[index];
                        if (translation) {
                          return new TranslationKey({
                            key: tag,
                            language: languageCode,
                            namespace: 'coach-app',
                            value: translation,
                          }).save();
                        }
                        return Promise.resolve();
                      }),
                    );
                  })
                  .catch((error) => {
                    console.error('Error translating tag:', tag, error);
                  });
              }
              return Promise.resolve();
            });
          }),
      ),
    )
    .catch((error) => {
      console.error('Error generating filter tags:', error);
    });
