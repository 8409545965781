/**
 * Created by neo on 21.06.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ExploreContent } from '../../../../Model/Explore/ExploreContent';
import { ExploreContentBuilder } from '../../../../Model/Explore/ExploreContentBuilder';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { useRootStore } from '../../../../Store/useRootStore';
import { generateExploreFilterTags } from '../ExploreEntryModal/generateExploreFilterTags';

export type FilterTagAllNonEmptyContentComponentProps = {};

export const FilterTagAllNonEmptyContentComponent: React.FC<FilterTagAllNonEmptyContentComponentProps> = observer(
  (props) => {
    const { authentication } = useRootStore();

    const [tagging, setTagging] = useState(false);

    const tagEntry = React.useRef((entry: ExploreEntry) => {
      if (entry.filterTags.length === 0) {
        return generateExploreFilterTags(entry).then((tags) => entry);
      }
      return Promise.resolve(entry);
    }).current;

    const tagContent = React.useRef(async () => {
      const SIZE = 10;
      let page = 0;
      let results: ExploreContent[] | undefined;
      while (!results || results.length >= SIZE) {
        results = await ExploreContentBuilder.find({ size: SIZE, contentType: 'entry', page });

        if (results.length > 0) {
          await Promise.all(results.map((e) => tagEntry(e as ExploreEntry).then(() => e.save())));
        }
        page += 1;
      }
    }).current;

    const handleTagAll = React.useCallback(async () => {
      setTagging(true);
      try {
        await tagContent();
      } finally {
        setTagging(false);
      }
    }, [tagContent]);

    if (authentication.isOneAboveAll) {
      return (
        <Button onClick={handleTagAll} disabled={tagging}>
          Filter Tag All Empty Content
        </Button>
      );
    }

    return null;
  },
);
