/**
 * Created by neo on 14.04.21.
 */
import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Form, Progress } from 'antd';
import { ViewMediaItem, ViewMediaItemProps } from '../../../../../Components/ViewMedia/ViewMediaItem';
import { Media } from '../../../../../Model/Media/Media';
import MediaService from '../../../../../Services/MediaService';

export type RouteChallengeLocationModalMediaProps = {
  media?: Media;
  text?: string;
  label?: string;
  onUploaded?: (media: Media[]) => void;
} & Pick<ViewMediaItemProps, 'onRemove' | 'onClick'>;

export const RouteChallengeLocationModalMedia: React.FC<RouteChallengeLocationModalMediaProps> = observer(
  ({ label, text = 'Drop images here', media, onRemove, onClick, onUploaded }) => {
    const [uploading, setUploading] = useState(false);
    const [uploadPercent, setUploadPercent] = useState(0);

    const handleDrop = React.useCallback(
      (files: File[]) => {
        setUploading(true);
        setUploadPercent(0);
        Promise.all(
          files.map((file) =>
            MediaService.uploadMedia(file, (progress) => {
              setUploadPercent((p) => Math.min(p, (progress.loaded / progress.total) * 100));
            }),
          ),
        )
          .then((medias) => onUploaded && onUploaded(medias))
          .catch((e) => console.log('failed', e))
          .finally(() => setUploading(false));
      },
      [onUploaded],
    );

    return (
      <Form.Item label={label}>
        {media ? (
          <ViewMediaItem media={media} onRemove={onRemove} onClick={onClick} />
        ) : (
          <React.Fragment>
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="dropzone"
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  {text}
                </div>
              )}
            </Dropzone>
            {uploading && (
              <Progress
                type="circle"
                percent={uploadPercent}
                format={(percent) => ((percent ?? 0) >= 100 ? 'Uploading' : `${percent?.toFixed(2)}%`)}
              />
            )}
          </React.Fragment>
        )}
      </Form.Item>
    );
  },
);
