/**
 * Created by neo on 16.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Col, Row } from 'reactstrap';
import { Map, MapProps, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Button, Collapse, CollapseProps, Form, Input, Popconfirm, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { RouteChallengeLocation } from '../../../../../Model/Engagement/RouteChallenge/RouteChallengeLocation';
import { RouteChallengeLocationEntry } from './RouteChallengeLocationEntry';
import { runInAction } from 'mobx';
import { RouteChallengeMapEditorMapContent } from './RouteChallengeMapEditorMapContent';
import { LocalizedValue } from '../../../../../Model/LocalizedValue';
import { RouteChallengeTeamPositions } from './RouteChallengeTeamPositions';
import { SingleColRow } from '../../../../../Components/SingleColRow';
import { calculateArcAndEncode } from './calculateArcAndEncode';
import { MapRegion } from '../../../../../Model/Engagement/RouteChallenge/MapRegion';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';
import dayjs from 'dayjs';

export type RouteChallengeMapEditorProps = Pick<MapProps, 'onBoundsChanged'> & {
  challenge: RouteChallenge;
  earnModel?: EarnModelTemplate;
};

const KINASTIC_HQ_POSITION = { lat: 47.3732061, lng: 8.5283241 };

export const RouteChallengeMapEditor: React.FC<RouteChallengeMapEditorProps> = observer(({ challenge, earnModel }) => {
  const [currentMapRegion, setCurrentMapRegion] = useState(challenge.mapCenter);

  const mapId = `challenge-map-${challenge.id}`;

  const map = useMap();

  const [selectedLocation, setSelectedLocation] = useState<RouteChallengeLocation | undefined>();

  const [directionsService, setDirectionServices] = useState<google.maps.DirectionsService | undefined>();
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | undefined>();
  const [geocoderService, setGeocoderService] = useState<google.maps.Geocoder | undefined>();
  const [query, setQuery] = useState('');

  const directionsLibrary = useMapsLibrary('routes');
  const geocoderLibrary = useMapsLibrary('geocoding');
  const placesLibrary = useMapsLibrary('places');

  const totalPossiblePointsPerDay =
    earnModel?.categories.flatMap((c) => c.rules).reduce((acc, em) => acc + em.maxPointsPerActivation, 0) ?? 0;
  const numberOfDays = dayjs.duration(dayjs(challenge.endDateTime).diff(challenge.startDateTime)).asDays();
  const locationsCount = challenge.locations.length - 1;
  const pointsPerLocation =
    locationsCount > 0
      ? Math.floor(
          (challenge.difficultyFactor * totalPossiblePointsPerDay * numberOfDays * challenge.maxMembersPerTeam) /
            locationsCount,
        )
      : 0;

  const recalculateRouteDebounce = React.useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    console.log('directionsLibrary', directionsLibrary);
    if (directionsLibrary) {
      setDirectionServices(new google.maps.DirectionsService());
    }
  }, [directionsLibrary]);

  useEffect(() => {
    if (placesLibrary) {
      setPlacesService(new placesLibrary.PlacesService(document.createElement('div')));
    }
  }, [placesLibrary]);

  useEffect(() => {
    if (geocoderLibrary) {
      setGeocoderService(new geocoderLibrary.Geocoder());
    }
  }, [geocoderLibrary]);

  const mapCenter = useMemo(() => {
    if (challenge) {
      return challenge.mapCenter
        ? { lat: challenge.mapCenter.latitude, lng: challenge.mapCenter.longitude }
        : KINASTIC_HQ_POSITION;
    }
    return undefined;
  }, [challenge]);

  console.log('directionsService', directionsService);
  console.log('geocoderService', geocoderService);

  const handleBoundsChanged = React.useCallback(({ detail }) => {
    console.log('bounds', detail);
    // lon = x, lat = y
    const longitudeDelta = Math.max(
      Math.abs(detail.bounds.west - detail.center.lng),
      Math.abs(detail.bounds.east - detail.center.lng),
    );
    const latitudeDelta = Math.max(
      Math.abs(detail.bounds.north - detail.center.lat),
      Math.abs(detail.bounds.south - detail.center.lat),
    ); // Math.abs(detail.bounds.north - detail.bounds.south);
    setCurrentMapRegion(
      new MapRegion({
        longitude: detail.center.lng,
        latitude: detail.center.lat,
        longitudeDelta,
        latitudeDelta,
      }),
    );
  }, []);

  const handleAddLocation = React.useCallback(() => {
    const selectedLocationIndex = challenge.locations.findIndex((l) => l.id === selectedLocation?.id);
    const newLocationIndex = selectedLocationIndex !== -1 ? selectedLocationIndex + 1 : undefined;
    const previousLocation = newLocationIndex
      ? challenge.locations[newLocationIndex - 1]
      : challenge.locations[challenge.locations.length - 1];
    const pointsRequired = Math.ceil((previousLocation?.pointsRequired ?? 0) * 1.1);
    const location = challenge.createLocation(newLocationIndex, { pointsRequired });

    console.log('previousLocation', previousLocation, newLocationIndex, selectedLocationIndex);

    setSelectedLocation(location);
  }, [challenge, selectedLocation]);

  const updateLocationName = React.useCallback(
    (location: RouteChallengeLocation, latLng: google.maps.LatLng) => {
      geocoderService
        ?.geocode({
          location: latLng,
        })
        .then((results) => {
          const locality = results.results.find((r) => r.types.includes('locality') || r.types.includes('political'));
          const newName = locality?.address_components.find((a) => a.types.includes('locality'))?.long_name;
          console.log('geocode', locality);
          if (locality && location.name.length <= 1 && newName) {
            runInAction(() => (location.name = [new LocalizedValue({ lang: 'en', value: newName })]));
          }
        });
    },
    [geocoderService],
  );

  const changeLocationPosition = React.useCallback(
    (location: RouteChallengeLocation, latLng: google.maps.LatLng) => {
      const locationIndex = challenge.locations.findIndex((l) => l.id === location.id);

      updateLocationName(location, latLng);

      if (locationIndex > 0) {
        const previousLocation = challenge.locations[locationIndex - 1];
        if (previousLocation.position.xorLat && previousLocation.position.yorLng) {
          console.log('calculate route to previous location', previousLocation);
          directionsService
            ?.route({
              origin: new google.maps.LatLng({
                lat: previousLocation.position.xorLat,
                lng: previousLocation.position.yorLng,
              }),
              destination: latLng,
              travelMode: google.maps.TravelMode.WALKING,
            })
            .then((res) => {
              console.log('route to previous', res);
            })
            .catch((err) => console.error('route error', err));
        }
      }

      if (locationIndex < challenge.locations.length - 1) {
        const nextLocation = challenge.locations[locationIndex + 1];
        if (nextLocation.position.xorLat && nextLocation.position.yorLng) {
          console.log('calculate route to next location', nextLocation);
          directionsService
            ?.route({
              origin: latLng,
              destination: new google.maps.LatLng({
                lat: nextLocation.position.xorLat,
                lng: nextLocation.position.yorLng,
              }),
              travelMode: google.maps.TravelMode.WALKING,
            })
            .then((res) => {
              console.log('route to next', res);
            })
            .catch((err) => console.error('route error', err));
        }
      }

      runInAction(() => {
        location.position.xorLat = latLng.lat();
        location.position.yorLng = latLng.lng();
      });
    },
    [challenge, directionsService, updateLocationName],
  );

  const handleMapClick = React.useCallback(
    ({ detail, map }) => {
      console.log('map click', detail, map);
      if (selectedLocation && !selectedLocation.position.xorLat && !selectedLocation.position.yorLng) {
        changeLocationPosition(selectedLocation, new google.maps.LatLng(detail.latLng));
      }
    },
    [changeLocationPosition, selectedLocation],
  );

  const recalculateRoute = React.useCallback(
    (previousLocation: RouteChallengeLocation, nextLocation: RouteChallengeLocation) => {
      directionsService
        ?.route({
          origin: new google.maps.LatLng({
            lat: previousLocation.position.xorLat,
            lng: previousLocation.position.yorLng,
          }),
          destination: new google.maps.LatLng({
            lat: nextLocation.position.xorLat,
            lng: nextLocation.position.yorLng,
          }),
          travelMode: google.maps.TravelMode.WALKING,
        })
        .then((res) => {
          const route = res.routes[0];
          console.log('result destination', res);
          if (route) {
            console.log('route to previous', res);
            runInAction(() => (previousLocation.googleMapsRouteToNextLocation = route.overview_polyline));
          }
          console.log('route to previous', res);
        })
        .catch((err) => {
          console.error('route error', err);
          runInAction(
            () =>
              (previousLocation.googleMapsRouteToNextLocation = calculateArcAndEncode(
                previousLocation.position.xorLat,
                previousLocation.position.yorLng,
                nextLocation.position.xorLat,
                nextLocation.position.yorLng,
                100,
              )),
          );
        });
    },
    [directionsService],
  );

  const recalculateAllRoutes = React.useCallback(() => {
    challenge.locations.forEach((location, index, array) => {
      if (index < array.length - 1) {
        recalculateRoute(location, array[index + 1]);
      }
    });
  }, [challenge, recalculateRoute]);

  const recalculatePoints = React.useCallback(() => {
    runInAction(() => {
      let totalPoints = 0;
      challenge.locations.forEach((location, index) => {
        if (index > 0) {
          location.pointsRequired = pointsPerLocation;
          totalPoints += location.pointsRequired;
          location.totalPointsRequired = totalPoints;
        } else {
          location.pointsRequired = 0;
          location.totalPointsRequired = 0;
        }
      });
    });
  }, [challenge, pointsPerLocation]);

  const handleSearch = React.useCallback(() => {
    if (query) {
      placesService?.textSearch(
        {
          query,
        },
        (results, status) => {
          if (results) {
            const result = results[0];
            console.log('search results', results, status);
            if (result) {
              // const selectedLocationIndex = challenge.locations.findIndex((l) => l.id === selectedLocation?.id);
              const newLocationIndex = challenge.locations.length;
              const previousLocation = challenge.locations[newLocationIndex - 1];
              const pointsRequired = Math.ceil((previousLocation?.pointsRequired ?? 0) * 1.1);

              const location = challenge.createLocation(newLocationIndex, {
                pointsRequired,
                name: [{ lang: 'en', value: query }],
                position: {
                  type: 'coordinates',
                  xorLat: result.geometry?.location?.lat() ?? 0,
                  yorLng: result.geometry?.location?.lng() ?? 0,
                },
              });

              if (previousLocation?.position.xorLat && previousLocation?.position.yorLng && result.geometry?.location) {
                console.log('calculate route to previous location', previousLocation);
                directionsService
                  ?.route({
                    origin: new google.maps.LatLng({
                      lat: previousLocation.position.xorLat,
                      lng: previousLocation.position.yorLng,
                    }),
                    destination: result.geometry?.location,
                    travelMode: google.maps.TravelMode.WALKING,
                  })
                  .then((res) => {
                    const route = res.routes[0];
                    console.log('result destination', res);
                    if (route) {
                      console.log('route to previous', res);
                      runInAction(() => (previousLocation.googleMapsRouteToNextLocation = route.overview_polyline));
                    }
                    console.log('route to previous', res);
                  })
                  .catch((err) => {
                    console.error('route error', err);
                    runInAction(
                      () =>
                        (previousLocation.googleMapsRouteToNextLocation = calculateArcAndEncode(
                          previousLocation.position.xorLat,
                          previousLocation.position.yorLng,
                          result.geometry?.location?.lat() ?? 0,
                          result.geometry?.location?.lng() ?? 0,
                          100,
                        )),
                    );
                  });
              }

              setQuery('');
              recalculatePoints();
            }
          }
        },
      );
    }
  }, [challenge, directionsService, placesService, query, recalculatePoints]);

  const handleRemoveLocation = React.useCallback(
    (location: RouteChallengeLocation) => {
      const index = challenge.locations.findIndex((l) => l.id === location.id);
      if (index !== -1) {
        runInAction(() => challenge.locations.splice(index, 1));

        if (index > 0) {
          if (index < challenge.locations.length - 1) {
            const previousLocation = challenge.locations[index - 1];
            const nextLocation = challenge.locations[index];
            recalculateRoute(previousLocation, nextLocation);
          } else {
            const previousLocation = challenge.locations[index - 1];
            runInAction(() => (previousLocation.googleMapsRouteToNextLocation = undefined));
          }
        }

        recalculatePoints();
      }
    },
    [challenge, recalculateRoute, recalculatePoints],
  );

  const handleChangeLocationIndex = React.useCallback(
    (location: RouteChallengeLocation, newIndex: number) => {
      const index = challenge.locations.findIndex((l) => l.id === location.id);
      if (index !== -1 && index !== newIndex) {
        runInAction(() => {
          challenge.locations.splice(newIndex, 0, challenge.locations.splice(index, 1)[0]);
        });

        recalculateRouteDebounce.current && clearTimeout(recalculateRouteDebounce.current);
        recalculateRouteDebounce.current = setTimeout(() => {
          recalculateAllRoutes();
          recalculatePoints();
        }, 500);
      }
    },
    [challenge, recalculateAllRoutes, recalculatePoints],
  );

  const collapseItems: CollapseProps['items'] = challenge.locations.map((location, index, array) => ({
    key: location.id,
    label: `${index + 1}. ${location.getName('en')} - ${location.pointsRequired} (${location.totalPointsRequired})`,
    extra: (
      <React.Fragment>
        {index > 0 && (
          <Button
            type="text"
            shape="circle"
            icon={<ArrowUpOutlined />}
            onClick={(e) => {
              e.preventDefault();
              handleChangeLocationIndex(location, index - 1);
            }}
          />
        )}
        {index < array.length - 1 && (
          <Button
            type="text"
            shape="circle"
            icon={<ArrowDownOutlined />}
            onClick={(e) => {
              e.preventDefault();
              handleChangeLocationIndex(location, index + 1);
            }}
          />
        )}
        <Popconfirm
          title={`Delete location ${location.getName('en')}?`}
          onConfirm={(e) => {
            e?.preventDefault();
            handleRemoveLocation(location);
          }}
        >
          <Button type="text" danger shape="circle" icon={<DeleteOutlined />}></Button>
        </Popconfirm>
      </React.Fragment>
    ),
    children: (
      <RouteChallengeLocationEntry
        challenge={challenge}
        location={location}
        selected={selectedLocation?.id === location.id}
      />
    ),
  }));

  return (
    <Row>
      <Col xs={12} style={{ marginTop: 16 }}>
        <h3>Route Editor</h3>
      </Col>
      <Col xs={12}>
        <Row>
          <Col>
            <Input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onSubmit={handleSearch}
              onKeyDown={(e) => (e.code === 'Enter' ? handleSearch() : undefined)}
            />
          </Col>
          <Col xs="auto">
            <Button type="primary" block onClick={handleSearch}>
              Search & Add
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={12} style={{ marginTop: 16 }}>
        <h5>Locations</h5>
        <Collapse collapsible="header" items={collapseItems} />
      </Col>
      <Col xs={12} style={{ marginTop: 16 }}>
        <SingleColRow>
          <h5>Map</h5>
        </SingleColRow>
        <Col xs={12}>
          <Form.Item
            label="Map Center (lat, lon, latDelta, lonDelta)"
            extra="Move the map around and set the zoom level accordingly. Press then the button  to set a new map center"
          >
            <Space>
              <Input value={challenge.mapCenter.latitude} disabled={true} />
              <Input value={challenge.mapCenter.longitude} disabled={true} />
              <Input value={challenge.mapCenter.latitudeDelta} disabled={true} />
              <Input value={challenge.mapCenter.longitudeDelta} disabled={true} />
              <Button onClick={() => runInAction(() => (challenge.mapCenter = currentMapRegion))}>
                Set Map Center
              </Button>
            </Space>
          </Form.Item>
        </Col>
        {mapCenter && (
          <Map
            mapId={mapId}
            defaultCenter={mapCenter}
            defaultZoom={7}
            style={{ height: 600 }}
            onClick={handleMapClick}
            onBoundsChanged={handleBoundsChanged}
          >
            {!selectedLocation && <RouteChallengeTeamPositions challenge={challenge} />}
            <RouteChallengeMapEditorMapContent
              challenge={challenge}
              selectedLocation={selectedLocation}
              onSelect={(location) => setSelectedLocation((prev) => (prev?.id === location.id ? undefined : location))}
            />
          </Map>
        )}
      </Col>
    </Row>
  );
});

const mapStyle = {
  width: '100%',
  height: '400px',
};
