import { Media, MediaJson } from '../Media/Media';
import { TagCondition, TagConditionJson } from '../Coach/SuperMacro/TagCondition';
import { VersionInformation, VersionInformationJson } from '../VersionInformation';
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { action, computed, observable, onBecomeObserved, runInAction, toJS } from 'mobx';
import { Pageable } from '../Pageable';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { Gym } from '../Gym/Gym';
import dayjs from '../../Utils/dayjs';
import { notUndefined } from '../../Utils/notUndefined';

export type ExploreContentType = 'category' | 'entry';

export type ExploreContentViewType = 'small_cards' | 'big_cards' | 'full_size';

export type ExploreContentPublishState = 'draft' | 'published' | 'unpublished';

export type ExploreContentQueryRequest = Pageable & {
  query?: string;
  type?: string[];
  categories?: string[];
  tags?: string[];
  gymId?: string;
  organizationId?: string;
  contentType?: string;
  root?: boolean;
};

/**
 * Created by neo on 11.03.22.
 */

export type ExploreContentJson = LocalizedArrayEntityJson & {
  contentType: string;
  type?: string;
  tags: string[];
  categories: string[];
  organizationId?: string;
  gymId?: string;
  order: number;
  images: MediaJson[];
  videos: MediaJson[];
  publishDate?: string;
  unpublishDate?: string;
  tagConditions: TagConditionJson[];
  version: VersionInformationJson;
  viewType?: ExploreContentViewType;
  shortDescription: LocalizedValueJson[];
  filterTags: string[];
};

export class ExploreContent extends LocalizedArrayEntity {
  @observable
  contentType: string = 'entry';
  @observable
  type: string = 'default';
  @observable
  tags: string[] = ['morning', 'afternoon', 'evening', 'night'];
  @observable
  categories: string[] = [];
  @observable
  organizationId?: string;
  @observable
  gymId?: string;
  @observable
  order = 0;
  @observable
  images: Media[] = [];
  @observable
  videos: Media[] = [];
  @observable
  publishDate?: Date;
  @observable
  unpublishDate?: Date;
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  version = new VersionInformation();
  @observable
  viewType?: ExploreContentViewType;
  @observable
  shortDescription: LocalizedValue[] = [];
  @observable
  filterTags: string[] = [];
  @observable
  gym?: Gym;

  constructor(json?: Partial<ExploreContentJson>) {
    super(json);
    if (json) {
      this.contentType = json.contentType ?? 'entry';
      this.type = json.type ?? 'preview';
      this.tags = json.tags ?? ['morning', 'afternoon', 'evening', 'night'];
      this.categories = json.categories ?? [];
      this.organizationId = json.organizationId;
      this.gymId = json.gymId ?? undefined;
      this.order = json.order ?? 0;
      this.images = json.images?.map((m) => new Media(m)) ?? [];
      this.videos = (json.videos ?? []).map((t) => new Media(t));
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.publishDate = json.publishDate ? new Date(json.publishDate) : undefined;
      this.unpublishDate = json.unpublishDate ? new Date(json.unpublishDate) : undefined;
      this.viewType = json.viewType;
      this.version = new VersionInformation(json.version);
      this.shortDescription = (json.shortDescription ?? []).map((s) => new LocalizedValue(s));
      this.filterTags = json.filterTags ?? [];
    }

    onBecomeObserved(this, 'gym', () => this.fetchGym());
  }

  toJS(newId: boolean = false): ExploreContentJson {
    return Object.assign(super.toJS(newId), {
      contentType: this.contentType,
      type: this.type,
      tags: toJS(this.tags),
      categories: toJS(this.categories),
      organizationId: this.organizationId,
      gymId: this.gymId,
      order: this.order,
      images: this.images.map((i) => i.toJS()),
      videos: this.videos.map((v) => v.toJS()),
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      publishDate: this.publishDate?.toISOString(),
      unpublishDate: this.unpublishDate?.toISOString(),
      viewType: this.viewType,
      version: this.version.toJS(),
      shortDescription: this.shortDescription.map((s) => s.toJS()),
      filterTags: this.filterTags,
    });
  }

  save(): Promise<ExploreContent> {
    return Promise.resolve(this);
  }

  fetchGym() {
    if (!this.gym && this.gymId) {
      Gym.get(this.gymId).then((res) => runInAction(() => (this.gym = res)));
    }
  }

  @computed
  get image(): Media | undefined {
    return this.images[0];
  }

  @computed
  get isRoot(): boolean {
    return this.categories.length === 0;
  }

  @computed
  get state(): ExploreContentPublishState {
    if (!this.publishDate) {
      return 'draft';
    } else if (!this.unpublishDate || dayjs(this.unpublishDate).isAfter(dayjs())) {
      return 'published';
    }

    return 'unpublished';
  }

  @computed
  get isAdmin(): boolean {
    return this.tagConditions.flatMap((t) => t.includedTags).includes('admin:true');
  }

  @computed
  get availableLanguages(): string[] {
    return this.videos
      .flatMap((s) => s.language)
      .filter((v) => !!v)
      .filter(notUndefined);
  }
}
