/**
 *
 */
import * as React from 'react';
import { Routes, Route } from 'react-router';
import { LoadingContainer } from './Components/LoadingContainer';
import { AuthScreen } from './View/Auth/AuthScreen';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRootStore } from './Store/useRootStore';
import { observer } from 'mobx-react';
import { MainLayout } from './View/Layout/MainLayout';
import { DashboardScreen } from './View/Dashboard/DashboardScreen';
import { GymListScreen } from './View/Infrastructure/Gym/GymListScreen';
import { BranchViewScreen } from './View/Infrastructure/Gym/Branch/BranchViewScreen';
import { GymViewScreen } from './View/Infrastructure/Gym/GymViewScreen/GymViewScreen';
import { CustomersListScreen } from './View/Infrastructure/Customers/CustomersListScreen';
import { EventProcessorsScreen } from './View/Infrastructure/Axon/View/EventProcessorsScreen';
import { MetaDataRouter } from './View/MetaData/MetaDataRouter';
import { CoachRouter } from './View/Coach/CoachRouter';
import { ActivityLogListScreen } from './View/Tracking/WorkoutLog/ActivityLogListScreen';
import { WorkoutLogViewScreen } from './View/Tracking/WorkoutLog/View/WorkoutLogViewScreen';
import { AnalyticsIndexScreen } from './View/Analytics/AnalyticsIndexScreen';
import { UserListScreen } from './View/Security/User/UserListScreen';
import { UserViewScreen } from './View/Security/User/View/UserViewScreen';
import { UserRoleListScreen } from './View/Security/UserRole/UserRoleListScreen';
import { ExerciseListScreen } from './View/MetaData/Exercise/ExerciseListScreen/ExerciseListScreen';
import { ExerciseViewScreen } from './View/MetaData/Exercise/View/ExerciseViewScreen';
import { NoMatch } from './NoMatch';
import { OAuthClientListScreen } from './View/Security/OAuth/OAuthClientListScreen';
import { AthleteListScreen } from './View/Athlete/AthleteListScreen';
import { AthleteViewScreen } from './View/Athlete/View/AthleteViewScreen';
import { OAuthClientViewScreen } from './View/Security/OAuth/View';
import { UserRoleViewScreen } from './View/Security/UserRole/View';
import { OAuthScopeListScreen } from './View/Security/OAuthScope/OAuthScopeListScreen';
import { OAuthScopeViewScreen } from './View/Security/OAuthScope/View/OAuthScopeViewScreen';
import { EngagementRouter } from './View/Engagement/EngagementRouter';
import { DynamicLinkBuilderScreen } from './View/Tools/DynamicLinkBuilderScreen';
import { FileStorageScreen } from './View/CustomerSuccess/FileStorage/FileStorageScreen';
import { FolderViewScreen } from './View/CustomerSuccess/FileStorage/FolderViewScreen';
import { CampaignCalendarIndexScreen } from './View/CustomerSuccess/CampaignCalendar/CampaignCalendarIndexScreen';
import { QrCodeBuilderScreen } from './View/Tools/QrCodeBuilderScreen';
import { DeeplToolScreen } from './View/Tools/DeeplToolScreen';
import { CompanyDashboardScreen } from './View/Analytics/CompanyDashboard/CompanyDashboardScreen';
import { StudioAnalyticsScreen } from './View/Analytics/StudioAnalytics/StudioAnalyticsScreen';

export type RootProps = {};

export const Root: React.FC<RootProps> = observer(() => {
  const [loading, setLoading] = useState(true);
  const { authentication } = useRootStore();
  const location = useLocation();
  const history = useNavigate();

  const checkRedirect = React.useCallback(async () => {
    const isAuthPage = location.pathname.startsWith('/auth');
    try {
      await authentication.checkAuthData();
      const isAdmin = authentication.athlete?.user.isAdmin;
      const path =
        '/auth' === location.pathname
          ? '/'
          : location.pathname.startsWith('/access-denied')
            ? isAdmin
              ? '/'
              : '/auth'
            : location.pathname;
      console.log('checkRedirect', location.pathname, isAuthPage, isAdmin, path);

      if (isAuthPage || location.pathname.startsWith('/access-denied')) {
        console.log(`checkRedirect redirecting to ${path}`);
        history(path, { replace: true });
      } else if (!isAdmin) {
        console.log('checkRedirect redirecting to /access-denied');
        history('/access-denied', { replace: true });
      }
    } catch (err) {
      const loginPath =
        '/auth' === location.pathname ? '/' : location.pathname.startsWith('/access-denied') ? '/' : location.pathname;

      console.log(`checkRedirect redirecting to ${`/auth?redirect=${loginPath}`}`);
      !isAuthPage && history(`/auth?redirect=${loginPath}`, { replace: true });
    }
  }, [authentication, history, location]);

  useEffect(() => {
    checkRedirect().finally(() => setLoading(false));
  }, [checkRedirect]);

  if (loading) {
    return <LoadingContainer text={'Loading Page...'} />;
  }

  return (
    <Routes>
      <Route path="/">
        <Route path="auth" element={<AuthScreen />} />
        <Route element={<MainLayout />}>
          <Route index element={<DashboardScreen />} />
          <Route path="infrastructure">
            <Route path="gym">
              <Route index element={<GymListScreen />} />
              <Route path=":gymId/*">
                <Route path="branch/:branchId" element={<BranchViewScreen />} />
                <Route path="*" element={<GymViewScreen />} />
              </Route>
            </Route>
            <Route path="customers">
              <Route index element={<CustomersListScreen />} />
            </Route>
            {authentication.isGodAdmin && <Route path="axon/*" element={<EventProcessorsScreen />} />}
          </Route>

          <Route path="athlete/*">
            <Route index element={<AthleteListScreen />} />
            <Route path=":athleteId/*" element={<AthleteViewScreen />} />
          </Route>
          <Route path="metadata/*" element={<MetaDataRouter />} />
          <Route path="engagement/*" element={<EngagementRouter />} />
          <Route path="coach/*" element={<CoachRouter />} />
          <Route path="tracking">
            <Route path="activity-log">
              <Route index element={<ActivityLogListScreen />} />
            </Route>
            <Route path="workout-log">
              <Route path=":workoutLogId" element={<WorkoutLogViewScreen />} />
            </Route>
          </Route>
          <Route path="analytics/*">
            <Route index element={<AnalyticsIndexScreen />} />
            <Route path="company-dashboard" element={<CompanyDashboardScreen />} />
            <Route path="studio-analytics" element={<StudioAnalyticsScreen />} />
          </Route>
          {authentication.isAdmin && (
            <Route path="customer-success/*">
              <Route path="campaign-calendar/*" element={<CampaignCalendarIndexScreen />} />
              <Route path="file-storage">
                <Route index element={<FileStorageScreen />} />
                <Route path={'folder/:folderId'} element={<FolderViewScreen />} />
              </Route>
            </Route>
          )}
          {authentication.isGodAdmin && (
            <Route path="security/*">
              <Route path="user">
                <Route index element={<UserListScreen />} />
                <Route path=":userId" element={<UserViewScreen />} />
              </Route>
              <Route path="userRole">
                <Route index element={<UserRoleListScreen />} />
                <Route path=":id" element={<UserRoleViewScreen />} />
              </Route>
              <Route path="oauth">
                <Route index element={<OAuthClientListScreen />} />
                <Route path=":clientId" element={<OAuthClientViewScreen />} />
              </Route>
              <Route path="oauthScope">
                <Route index element={<OAuthScopeListScreen />} />
                <Route path=":id" element={<OAuthScopeViewScreen />} />
              </Route>
            </Route>
          )}
          <Route path="tools">
            <Route path="dynamic-link-builder" element={<DynamicLinkBuilderScreen />} />
            <Route path="qr-code-builder" element={<QrCodeBuilderScreen />} />
            <Route path="deepl" element={<DeeplToolScreen />} />
          </Route>
          <Route path="modal">
            <Route path="media">
              <Route index element={<ExerciseListScreen />} />
              <Route path=":exerciseId" element={<ExerciseViewScreen />} />
            </Route>
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Route>
    </Routes>
  );
});
