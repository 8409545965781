function interpolateGreatCircle(
  startLat: number,
  startLng: number,
  endLat: number,
  endLng: number,
  numPoints: number,
): google.maps.LatLng[] {
  const start = new google.maps.LatLng(startLat, startLng);
  const end = new google.maps.LatLng(endLat, endLng);

  const path: google.maps.LatLng[] = [];
  for (let i = 0; i <= numPoints; i++) {
    const fraction = i / numPoints;
    const interpolatedPoint = google.maps.geometry.spherical.interpolate(start, end, fraction);
    path.push(interpolatedPoint);
  }

  return path;
}

// Define a function to calculate and encode the arc
export function calculateArcAndEncode(
  startLat: number,
  startLng: number,
  endLat: number,
  endLng: number,
  numPoints: number,
): string {
  const path = interpolateGreatCircle(startLat, startLng, endLat, endLng, numPoints);
  const polyline = new google.maps.Polyline({ path });

  // Encode the polyline
  const encodedPath = google.maps.geometry.encoding.encodePath(polyline.getPath());

  return encodedPath;
}
