/**
 * Created by neo on 22.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Col, Row } from 'reactstrap';
import { Button, Collapse, CollapseProps, Form, Input, message, Space } from 'antd';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import { RouteChallengeMapEditor } from './RouteChallengeMapEditor';
import { RouteChallengeParameters } from './RouteChallengeParameters';
import { RouteChallengeImages } from './RouteChallengeImages';
import { EarnModelTemplate } from '../../../../../Model/Engagement/EarnModelTemplate';

export type RouteChallengeEditorTabProps = {
  challenge: RouteChallenge;
  earnModel?: EarnModelTemplate;
};

export const RouteChallengeEditorTab: React.FC<RouteChallengeEditorTabProps> = observer(({ challenge, earnModel }) => {

  const items: CollapseProps['items'] = [
    {
      key: 'name',
      label: 'Challenge Name',
      children: <TranslationInputArray entity={challenge} field={'name'} />,
    },
    {
      key: 'description',
      label: 'Challenge Description',
      children: <TranslationInputArray entity={challenge} type="textarea" field={'description'} markdown={true} />,
    },
    {
      key: 'parameters',
      label: 'Challenge Parameters',
      children: <RouteChallengeParameters challenge={challenge} earnModel={earnModel} />,
    },
    {
      key: 'images',
      label: 'Images',
      children: <RouteChallengeImages challenge={challenge} />,
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Collapse items={items} />
        </Col>
        <Col xs={12}>
          <RouteChallengeMapEditor challenge={challenge} earnModel={earnModel} />
        </Col>
      </Row>
    </React.Fragment>
  );
});
