/**
 * Created by neo on 16.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteChallenge } from '../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Alert, Button, DatePicker, Form, InputNumber, message, Popconfirm, Space, Tabs } from 'antd';
import { Col, Container, Row } from 'reactstrap';
import { RouteChallengeTeamsTab } from './RouteChallengeTeamsTab/RouteChallengeTeamsTab';
import { SingleColRow } from '../../../../Components/SingleColRow';
import { RouteChallengeEditorTab } from './RouteChallengeEditorTab/RouteChallengeEditorTab';
import { useRootStore } from '../../../../Store/useRootStore';
import { EarnModelTemplate } from '../../../../Model/Engagement/EarnModelTemplate';
import { reaction } from 'mobx';
import dayjs from 'dayjs';

export type RouteChallengeViewScreenProps = {};

export const RouteChallengeViewScreen: React.FC<RouteChallengeViewScreenProps> = observer((props) => {
  const { authentication } = useRootStore();
  const { challengeId } = useParams<{ challengeId: string }>();
  const history = useNavigate();

  const [challenge, setChallenge] = useState(new RouteChallenge());
  const [earnModel, setEarnModel] = useState<EarnModelTemplate | undefined>();

  const isOneAboveAll = authentication.isOneAboveAll;

  const totalPossiblePointsPerDay =
    earnModel?.categories.flatMap((c) => c.rules).reduce((acc, em) => acc + em.maxPointsPerActivation, 0) ?? 0;
  const numberOfDays = dayjs.duration(dayjs(challenge.endDateTime).diff(challenge.startDateTime)).asDays();
  const locationsCount = challenge.locations.length - 1;
  const pointsPerLocation =
    locationsCount > 0
      ? Math.floor(
          (challenge.difficultyFactor * totalPossiblePointsPerDay * numberOfDays * challenge.maxMembersPerTeam) /
            locationsCount,
        )
      : 0;
  const recalculationRequired =
    pointsPerLocation > 0 &&
    challenge.locations.some((l, index) => (index === 0 ? false : l.pointsRequired !== pointsPerLocation));

  useEffect(() => {
    if (!challengeId || challengeId === 'new') {
      setChallenge(new RouteChallenge());
    } else {
      RouteChallenge.findOne(challengeId).then((result) => setChallenge(result));
    }
  }, [challengeId]);

  useEffect(
    () =>
      reaction(
        () => challenge.gymId,
        (gymId) => {
          EarnModelTemplate.find({ gymId })
            .then((res) => (res[0] ? res : EarnModelTemplate.find()))
            .then((res) => setEarnModel(res[0]));
        },
        { fireImmediately: true },
      ),
    [challenge],
  );

  const handleSave = React.useCallback(() => {
    challenge
      .save()
      .then(() => history(`/engagement/route-challenges/${challenge.id}`, { replace: true }))
      .then(() => message.success('Challenge saved'))
      .catch(() => message.error('Failed to save challenge'));
  }, [challenge, history]);

  const handleStart = React.useCallback(() => {
    challenge
      .start()
      .then(() => message.success('Challenge started'))
      .catch(() => message.error('Failed to start challenge'));
  }, [challenge]);

  const handleEnd = React.useCallback(() => {
    challenge
      .end()
      .then(() => message.success('Challenge ended'))
      .catch(() => message.error('Failed to end challenge'));
  }, [challenge]);

  const handleDelete = () => {
    challenge.delete().then(() => history(`/engagement/route-challenges`, { replace: true }));
  };

  return (
    <Container>
      <Form layout="vertical">
        <Row>
          <Col>
            <h2>Route Challenge Editor</h2>
            <h5>{challenge.defaultName}</h5>
            {recalculationRequired && dayjs(challenge.startDateTime).isAfter(dayjs()) ? (
              <Alert message="Recalcuation of the points required! Open 'Challenge Parameters'" type="warning" />
            ) : null}
          </Col>
          <Col xs="auto">
            <Space>
              <Button
                type="primary"
                onClick={handleSave}
                // disabled={!isOneAboveAll && (challenge.started || challenge.ended)}
              >
                Save
              </Button>
              {!challenge.started && (
                <Popconfirm title={'Are you sure you want to start this challenge?'} onConfirm={handleStart}>
                  <Button>Start Challenge</Button>
                </Popconfirm>
              )}
              {challenge.started && !challenge.ended && (
                <Popconfirm title={'Are you sure you want to end this challenge?'} onConfirm={handleEnd}>
                  <Button type="primary" danger>
                    End Challenge
                  </Button>
                </Popconfirm>
              )}
              {((!challenge.started && !challenge.ended) || isOneAboveAll) && (
                <Popconfirm title={'Are you sure you want to delete this challenge?'} onConfirm={handleDelete}>
                  <Button type="primary" danger>
                    Delete Challenge
                  </Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
        </Row>
        <SingleColRow>
          <Tabs defaultActiveKey="editor">
            <Tabs.TabPane key="editor" tab="Editor">
              <RouteChallengeEditorTab challenge={challenge} earnModel={earnModel} />
            </Tabs.TabPane>
            <Tabs.TabPane key="teams" tab="Teams">
              <RouteChallengeTeamsTab challenge={challenge} />
            </Tabs.TabPane>
          </Tabs>
        </SingleColRow>
      </Form>
    </Container>
  );
});
