import { GptResponseService } from '../../../../Services/GptResponseService';
import { runInAction } from 'mobx';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { WorkoutTemplateEntry } from '../../../../Model/Explore/WorkoutTemplateEntry';
import { ActivityWorkoutEntry } from '../../../../Model/Explore/ActivityWorkoutEntry';

const fetchType = (type) => {
  if (type === 'workoutTemplate') {
    return ' single workout';
  } else if (type === 'workplace') {
    return 'a single workout that you can do at work';
  } else if (type === 'activityWorkout') {
    return 'a single video based workout';
  } else if (type === 'meditation') {
    return 'a meditation';
  } else if (type === 'breathing') {
    return 'a breathing exercise';
  } else if (type === 'yoga') {
    return 'a video based single yoga class';
  } else if (type === 'onlineMeeting') {
    return 'an online live video';
  } else if (type === 'offlineMeeting') {
    return 'an on site event';
  } else if (type === 'webinar') {
    return 'a webinar';
  }
  return 'an article';
};

const fetchDescription = (entry: ExploreEntry) => {
  if (entry.getDescription('en')?.trim()) {
    return Promise.resolve(entry.getDescription('en')?.trim());
  } else if (entry instanceof WorkoutTemplateEntry) {
    return entry.fetchWorkoutTemplate().then((t) => t?.getDescription('en')?.trim() ?? '');
  }
  return Promise.resolve('');
};

const fetchPrompt = (entry: ExploreEntry) => {
  return fetchDescription(entry).then((description) => {
    if (entry instanceof WorkoutTemplateEntry) {
      if (entry.context.equipmentTypes.length > 0) {
        if (entry.context.isWithoutEquipment) {
          return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
            'en',
          )}. Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry.
             It does not require any equipment and it's done only using your body weight. Include the tags "without equipment" and include "equipment:custom" and "equipment:gym" within the list of tags. It's mandatory.. ${
               entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
             } It has the following description: "${description}. You have to include the tag "equipment:free" within the list of tags.`;
        }
        return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName('en')}.${
          entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
        } It needs following equipment: ${entry.context.equipmentTypes
          .filter((e) => e !== 'FREE' && e !== 'FREE_OUTDOOR')
          .join(',')}. Include minimum the tag "with equipment". 
              Include "equipment:custom" and "equipment:gym" within the list of tags. It's mandatory.
              Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
              or just the ones you seem appropriate for this entry. 
              It has the following description: "${description}"`;
      }

      return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName('en')}.${
        entry.durationInMinutes > 0 ? `The workout takes around ${entry.durationInMinutes} minutes.` : ''
      } It can be done in a gym or with your own equipment at home. Include minimum the tag "with equipment". 
          It has the following description: "${description}.
          If you need equipment for this workout then you have to include the tag "equipment:custom" and/or "equipment:gym". 
          If this workout requires no equipment then you have to include "equipment:free" AND "equipment:no" within the tags.
          Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry."`;
    } else if (entry instanceof ActivityWorkoutEntry) {
      return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
        'en',
      )}. It has the following description: "${description}. 
          If you need equipment for this workout then you have to include the tag "equipment:custom" and/or "equipment:gym". 
          If this workout requires no equipment then you have to include "equipment:free" AND "equipment:no" within the tags.
          Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry.`;
    }
    return `Give me a maximum 7 tags (or less) for ${fetchType(entry.type)} named ${entry.getName(
      'en',
    )}. Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry. It has the following description: "${description}".
        The JSON of this entry looks the following: 
          ${JSON.stringify(entry, null, 2)}`;
  });
};

export const generateExploreEntryTags = (entry: ExploreEntry) =>
  fetchPrompt(entry)
    .then((prompt) =>
      new GptResponseService([
        {
          role: 'system',
          content:
            'Provide all responses as JSON array containing only strings. The response has to start with "[" and end with "]". It HAS to be a JSON array only no other characters or text before and after the JSON array.',
        },
        {
          role: 'user',
          content: prompt,
        },
      ]).generate(),
    )
    .then((result) => JSON.parse(result))
    // .then((res) =>
    //   runInAction(
    //     () =>
    //       (entry.tags = [
    //         ...new Set<string>(
    //           res.map((t) => t.toLowerCase().trim()).concat(['afternoon', 'night', 'evening', 'morning']),
    //         ),
    //       ]),
    //   ),
    // )
    .then((res) => runInAction(() => (entry.tags = [...new Set<string>(res.map((t) => t.toLowerCase().trim()))])));
