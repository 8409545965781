/**
 * Created by neo on 16.10.2024
 */
import { LocalizedArrayEntity, LocalizedArrayEntityJson } from '../LocalizedArrayEntity';
import { Media, MediaJson } from '../Media/Media';
import { observable, onBecomeObserved, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { Pageable } from '../Pageable';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Gym } from '../Gym/Gym';

export type HighlightContentEntryQueryRequest = Pageable & {
  gymId?: string;
  category?: string;
  startDateTime?: Date;
  endDateTime?: Date;
};

export type HighlightContentEntryJson = LocalizedArrayEntityJson & {
  internalName: string;
  category: string;
  gymId?: string;
  medias: MediaJson[];
  appLink?: string;
  publishDate: string;
  unpublishDate: string;
  order: number;
};

export class HighlightContentEntry extends LocalizedArrayEntity {
  @observable
  internalName: string = '';
  @observable
  category: string = '';
  @observable
  gymId: string | undefined;
  @observable
  medias: Media[] = [];
  @observable
  appLink: string | undefined;
  @observable
  publishDate: Date = new Date();
  @observable
  unpublishDate: Date = dayjs().add(1, 'week').toDate();
  @observable
  order: number = 0;

  @observable
  gym?: Gym;

  constructor(json?: Partial<HighlightContentEntryJson>) {
    super(json);
    if (json) {
      this.internalName = json.internalName ?? '';
      this.category = json.category ?? '';
      this.gymId = json.gymId;
      this.medias = json.medias?.map((r) => new Media(r)) ?? [];
      this.appLink = json.appLink;
      this.publishDate = json.publishDate ? new Date(json.publishDate) : new Date();
      this.unpublishDate = json.unpublishDate ? new Date(json.unpublishDate) : dayjs().add(1, 'week').toDate();
      this.order = json.order ?? 0;
    }

    onBecomeObserved(this, 'gym', () => {
      this.fetchGym();
    });
  }

  fetchGym() {
    if (this.gymId && !this.gym) {
      Gym.get(this.gymId).then((result) => {
        runInAction(() => (this.gym = result));
        return result;
      });
    }
    return Promise.resolve(this.gym);
  }

  toJS(newId: boolean = false): HighlightContentEntryJson {
    return Object.assign(super.toJS(newId), {
      internalName: this.internalName,
      category: this.category,
      gymId: this.gymId,
      medias: this.medias.map((m) => m.toJS()),
      appLink: this.appLink,
      publishDate: this.publishDate.toISOString(),
      unpublishDate: this.unpublishDate.toISOString(),
      order: this.order,
    });
  }

  save(): Promise<HighlightContentEntry> {
    return HttpBackend.post(`/coach/content/highlight/admin`, this.toJS()).then(
      (res) => new HighlightContentEntry(res),
    );
  }

  delete(): Promise<HighlightContentEntry> {
    return HttpBackend.delete(`/coach/content/highlight/admin/${this.id}`).then(() => this);
  }

  static find(request?: Partial<HighlightContentEntryQueryRequest>): Promise<HighlightContentEntry[]> {
    return HttpBackend.get(`/coach/content/highlight/admin`, request).then((res) =>
      (res ?? []).map((r) => new HighlightContentEntry(r)),
    );
  }

  static count(request?: Partial<HighlightContentEntryQueryRequest>): Promise<number> {
    return HttpBackend.get(`/coach/content/highlight/admin/count`, request).then((res) => res ?? 0);
  }
}
